<template>
    <!-- Section - Blog -->
    <section class="lui-section lui-gradient-top" id="blog-section">

<!-- Heading -->
<div class="lui-heading">
    <div class="container">

    <div class="m-titles align-center">
        <h2 class="m-title splitting-text-anim-1 scroll-animate" data-splitting="words" data-animate="active">
        <span> Latest Blog </span>
        </h2>
        <div class="m-subtitle splitting-text-anim-1 scroll-animate" data-splitting="words" data-animate="active">
        <span> my <b>Articles and Advice</b>
        </span>
        </div>
    </div>

    </div>
        </div>

<!-- Archive -->
        <div class="v-line v-line-right">
    <div class="container">

    <div class="blog-items row">
        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
        <div class="archive-item scrolla-element-anim-1 scroll-animate" data-animate="active">
            <div class="image">
            <a href="#skills-section">
                <img decoding="async" src="assets/images/single7.jpg" alt="The Main Thing For The Designer" >
            </a>
            </div>
            <div class="desc">
            <div class="category lui-subtitle">
                <span>October 31, 2022</span>
            </div>
            <h5 class="lui-title">
                <a href="#skills-section">The Main Thing For The Designer</a>
            </h5>
            <div class="lui-text">
                <p>Vivamus interdum suscipit lacus. Nunc ultrices accumsan mattis. Aliquam vel sem vel velit efficitur malesuada. Donec arcu lacus, ornare eget&#8230; </p>
                <div class="readmore">
                <a href="#skills-section" class="lnk">Read more</a>
                </div>
            </div>
            </div>
        </div>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
        <div class="archive-item scrolla-element-anim-1 scroll-animate" data-animate="active">
            <div class="image">
            <a href="#skills-section">
                <img decoding="async" src="assets/images/blog-4-scaled-1.jpg" alt="Follow Your Own Design Process" >
            </a>
            </div>
            <div class="desc">
            <div class="category lui-subtitle">
                <span>October 31, 2022</span>
            </div>
            <h5 class="lui-title">
                <a href="#skills-section">Follow Your Own Design Process</a>
            </h5>
            <div class="lui-text">
                <p>Vivamus interdum suscipit lacus. Nunc ultrices accumsan mattis. Aliquam vel sem vel velit efficitur malesuada. Donec arcu lacus, ornare eget&#8230; </p>
                <div class="readmore">
                <a href="#skills-section" class="lnk">Read more</a>
                </div>
            </div>
            </div>
        </div>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
        <div class="archive-item scrolla-element-anim-1 scroll-animate" data-animate="active">
            <div class="image">
            <a href="#skills-section">
                <img decoding="async" src="assets/images/blog-2.jpg" alt="Usability Secrets to Create Better Interfaces" >
            </a>
            </div>
            <div class="desc">
            <div class="category lui-subtitle">
                <span>November 28, 2021</span>
            </div>
            <h5 class="lui-title">
                <a href="#skills-section">Usability Secrets to Create Better Interfaces</a>
            </h5>
            <div class="lui-text">
                <p>Vivamus interdum suscipit lacus. Nunc ultrices accumsan mattis. Aliquam vel sem vel velit efficitur malesuada. Donec arcu lacus, ornare eget&#8230; </p>
                <div class="readmore">
                <a href="#skills-section" class="lnk">Read more</a>
                </div>
            </div>
            </div>
        </div>
        </div>
    </div>

    <div class="load-more">
        <a href="blog.html" class="btn scrolla-element-anim-1 scroll-animate" data-animate="active">
        <span>View Blog</span>
        </a>
    </div>

    <div class="lui-bgtitle">
        <span> Blog </span>
    </div>

            </div>
        </div>

</section>
</template>
<script>
export default {
    name: "BlogComponents"
}
</script>