<template>
    <!-- Section - Skills -->
    <section class="lui-section lui-gradient-center" id="skills-section">

        <!-- Heading -->
        <div class="lui-heading">
            <div class="container">

                <div class="m-titles align-center">
                    <h2 class="m-title splitting-text-anim-1 scroll-animate" data-splitting="words" data-animate="active">
                    <span> My Skills </span>
                    </h2>
                    <div class="m-subtitle splitting-text-anim-1 scroll-animate" data-splitting="words" data-animate="active">
                    <span> my <b>Talent</b> </span>
                    </div>
                </div>

            </div>
        </div>

        <!-- Skills -->
        <div class="v-line v-line-left">
            <div class="container">

                <div class="row">
                    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">

                    <div class="skills-items">
                        <div class="skills-item scrolla-element-anim-1 scroll-animate" data-animate="active">
                            <h6 class="name">
                                <span> PHP </span>
                            </h6>
                            <div class="text">
                                <div>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>
                            </div>
                            <div class="dots">
                                <div class="dot" style="width: 85%;">
                                <span></span>
                                </div>
                            </div>
                            <div class="value">
                                <span class="num">85 <span>%</span>
                                </span>
                            </div>
                            </div>
                            <div class="skills-item scrolla-element-anim-1 scroll-animate" data-animate="active">
                            <h6 class="name">
                                <span> Python </span>
                            </h6>
                            <div class="text">
                                <div>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>
                            </div>
                            <div class="dots">
                                <div class="dot" style="width: 75%;">
                                <span></span>
                                </div>
                            </div>
                            <div class="value">
                                <span class="num">75 <span>%</span>
                                </span>
                            </div>
                        </div>
                    </div>

                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">

                    <div class="skills-items">
                        <div class="skills-item scrolla-element-anim-1 scroll-animate" data-animate="active">
                        <h6 class="name">
                            <span> JavaScript </span>
                        </h6>
                        <div class="text">
                            <div>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            </div>
                        </div>
                        <div class="dots">
                            <div class="dot" style="width: 75%;">
                            <span></span>
                            </div>
                        </div>
                        <div class="value">
                            <span class="num">75 <span>%</span>
                            </span>
                        </div>
                        </div>
                        <div class="skills-item scrolla-element-anim-1 scroll-animate" data-animate="active">
                        <h6 class="name">
                            <span> React </span>
                        </h6>
                        <div class="text">
                            <div>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            </div>
                        </div>
                        <div class="dots">
                            <div class="dot" style="width: 70%;">
                            <span></span>
                            </div>
                        </div>
                        <div class="value">
                            <span class="num">70 <span>%</span>
                            </span>
                        </div>
                        </div>
                    </div>

                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">

                    <div class="skills-items">
                        <div class="skills-item scrolla-element-anim-1 scroll-animate" data-animate="active">
                        <h6 class="name">
                            <span> WordPress </span>
                        </h6>
                        <div class="text">
                            <div>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            </div>
                        </div>
                        <div class="dots">
                            <div class="dot" style="width: 90%;">
                            <span></span>
                            </div>
                        </div>
                        <div class="value">
                            <span class="num">90 <span>%</span>
                            </span>
                        </div>
                        </div>
                        <div class="skills-item scrolla-element-anim-1 scroll-animate" data-animate="active">
                        <h6 class="name">
                            <span> Adobe XD </span>
                        </h6>
                        <div class="text">
                            <div>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            </div>
                        </div>
                        <div class="dots">
                            <div class="dot" style="width: 80%;">
                            <span></span>
                            </div>
                        </div>
                        <div class="value">
                            <span class="num">80 <span>%</span>
                            </span>
                        </div>
                        </div>
                    </div>

                    </div>
                </div>

            <div class="lui-bgtitle">
                <span> Skills </span>
            </div>

                </div>
            </div>

    </section>
</template>

<script>
export default {
    name: "SkillComponents",

}
;</script>