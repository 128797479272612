<template>
    <div class="preloader">
        <div class="centrize full-width">
            <div class="vertical-center">
                <div class="spinner-logo">
                    <img src="assets/images/logo-new-2.png" alt="Dhaffa Abdillah Hakim" >
                    <div class="spinner-dot"></div>
                    <div class="spinner spinner-line"></div>
                </div>
            </div>
        </div>
    </div>
</template>