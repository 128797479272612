<template>
    <!-- Section - Hero Started -->
    <section class="lui-section lui-section-hero lui-gradient-top" id="started-section">
        <div class="container">

            <!-- Hero Started -->
            <div class="lui-started v-line v-line-left">
                <div class="section hero-started">
                    <div class="content scrolla-element-anim-1 scroll-animate" data-animate="active">
                        <div class="titles">
                            <div class="lui-subtitle">
                                <span> Hello, <b>my name is</b>
                                </span>
                            </div>
                            <h1 class="title splitting-text-anim-1 scroll-animate" data-splitting="chars" data-animate="active">
                                <span><b>{{ firstName }}</b> {{ lastName}} </span>
                            </h1>
                            <div class="label lui-subtitle"> I am <strong>Web Developer</strong>
                            </div>
                        </div>
                        <div class="description">
                            <div>
                                <p>{{ description }}</p>
                            </div>
                            <div class="social-links">
                                <a target="_blank" rel="nofollow" href="#">
                                    <i aria-hidden="true" class="fab fa-twitter"></i>
                                </a>
                                <a target="_blank" rel="nofollow" href="#">
                                    <i aria-hidden="true" class="fab fa-dribbble"></i>
                                </a>
                                <a target="_blank" rel="nofollow" href="#">
                                    <i aria-hidden="true" class="fab fa-behance"></i>
                                </a>
                            </div>
                        </div>
                        <div class="bts">
                            <a target="_blank" href="https://drive.google.com/" class="btn">
                                <span>Download CV</span>
                            </a>
                            <a href="#skills-section" class="btn-lnk"> My Skills </a>
                        </div>
                    </div>
                    <div class="slide scrolla-element-anim-1 scroll-animate" data-animate="active">
                        <img decoding="async" src="assets/images/profile2.png" alt="&lt;b&gt;Dhaffa&lt;/b&gt; Abdillah Hakim" >
                        <span class="circle circle-1"></span>
                        <span class="circle img-1" style="background-image: url(assets/images/pat-1.png);"></span>
                        <span class="circle img-2" style="background-image: url(assets/images/pat-2.png);"></span>
                        <span class="circle img-3" style="background-image: url(assets/images/pat-2.png);"></span>
                        <div class="info-list">
                            <ul>
                                <li>
                                    <span class="num">1 <strong></strong>
                                    </span>
                                    <span class="value">Year of <strong>Experience</strong>
                                    </span>
                                </li>
                                <li>
                                    <span class="num">5</span>
                                    <span class="value">Completed <strong>Projects</strong>
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="lui-bgtitle">
                    <span> Web Developer </span>
                </div>
            </div>

        </div>
    </section>
</template>

<script>
export default {
    name: "StartedSectionComponents",
    data() {
        return {
            firstName: "Dhaffa",
            lastName: "Abdillah",
            description: "From Jakarta, Indonesia. I have some experiences in Website Development, especially in Backend Development. Also I am good at video editing."
        }
    }
}
</script>