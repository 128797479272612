<template>
    <!-- Footer -->
    <div class="footer">
        <div class="footer__builder">
            <div class="container">
                <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">

                    <div class="social-links scrolla-element-anim-1 scroll-animate" data-animate="active">
                    <a target="_blank" rel="nofollow" href="http://github.com/dhaffaabdillah">
                        <i aria-hidden="true" class="fab fa-github"></i>
                    </a>
                    <a target="_blank" rel="nofollow" href="http://instagram.com/dhaffaabdillah">
                        <i aria-hidden="true" class="fab fa-instagram"></i>
                    </a>
                    <a target="_blank" rel="nofollow" href="http://linkedin.com/in/dhaffaabdillah">
                        <i aria-hidden="true" class="fab fa-linkedin"></i>
                    </a>
                    </div>

                </div>
                <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">

                    <div class="copyright-text align-center scrolla-element-anim-1 scroll-animate" data-animate="active">
                    © 2023 <strong>Dhaffaabdillah</strong>. All rights reserved
                    </div>

                </div>
                <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">

                    <div class="copyright-text align-right scrolla-element-anim-1 scroll-animate" data-animate="active">
                    Redesigned by <strong>dhaffaabdillah</strong>
                    </div>

                </div>
                </div>
            </div>
        </div>
    </div>
</template>