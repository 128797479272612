<template>
    <!-- Section - Contacts -->
    <section class="lui-section lui-gradient-bottom" id="contact-section">

        <!-- Heading -->
        <div class="lui-heading">
            <div class="container">

            <div class="m-titles align-center">
                <h2 class="m-title splitting-text-anim-1 scroll-animate" data-splitting="words" data-animate="active">
                <span> Contact Me </span>
                </h2>
                <div class="m-subtitle splitting-text-anim-1 scroll-animate" data-splitting="words" data-animate="active">
                <span> Let’s <b>Talk About Ideas</b>
                </span>
                </div>
            </div>

            </div>
                </div>

        <!-- Contact -->
                <div class="lui-contacts v-line v-line-left">
            <div class="container">

            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5">

                <div class="numbers-items">
                    <div class="numbers-item scrolla-element-anim-1 scroll-animate" data-animate="active">
                    <div class="icon">
                        <i aria-hidden="true" class="far fa-map"></i>
                    </div>
                    <div class="title">
                        <span> Address </span>
                    </div>
                    <div class="lui-text">
                        <span> Depok, West Java, Indonesia </span>
                    </div>
                    </div>
                    <!-- <div class="numbers-item scrolla-element-anim-1 scroll-animate" data-animate="active">
                    <div class="icon">
                        <i aria-hidden="true" class="far fa-user"></i>
                    </div>
                    <div class="title">
                        <span> Freelance </span>
                    </div>
                    <div class="lui-text">
                        <span> Available Right Now </span>
                    </div>
                    </div> -->
                    <div class="numbers-item scrolla-element-anim-1 scroll-animate" data-animate="active">
                    <div class="icon">
                        <i aria-hidden="true" class="far fa-envelope"></i>
                    </div>
                    <div class="title">
                        <span> Email </span>
                    </div>
                    <div class="lui-text">
                        <span> dhaffdhaff1@gmail.com </span>
                    </div>
                    </div>
                    <div class="numbers-item scrolla-element-anim-1 scroll-animate" data-animate="active">
                    <div class="icon">
                        <i aria-hidden="true" class="far fa-address-book"></i>
                    </div>
                    <div class="title">
                        <span> Phone </span>
                    </div>
                    <div class="lui-text">
                        <span> +62 882-1083-7569 </span>
                    </div>
                    </div>
                </div>

                </div>
                <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7">

                <div class="contacts-form scrolla-element-anim-1 scroll-animate" data-animate="active">
                    <div class="bg-img" style="background-image: url(assets/images/pat-1.png);"></div>
                    <div class="contacts-form">
                    <form id="cform" method="post">
                        <div class="row">
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                            <div class="group">
                            <label>
                                Your Full Name <b>*</b>
                                <input type="text" name="name" >
                            </label>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                            <div class="group">
                            <label>
                                Your Email Address <b>*</b>
                                <input type="email" name="email" >
                            </label>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <div class="group">
                            <label>
                                Your Subject <b>*</b>
                                <input type="text" name="subject" >
                            </label>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <div class="group">
                            <label>
                                Your Message <b>*</b>
                                <textarea name="message"></textarea>
                            </label>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 align-right">
                            <div class="terms-label">* Accept the terms and conditions.</div>
                            <a href="#" class="btn" onclick="$('#cform').submit(); return false;">
                            <span>Send Message</span>
                            </a>
                        </div>
                        </div>
                    </form>
                    <div class="alert-success" style="display: none;">
                        <p>Thanks, your message is sent successfully.</p>
                    </div>
                    </div>

                </div>

                </div>
            </div>

            <div class="lui-bgtitle">
                <span> Contact Me </span>
            </div>

        </div>
        </div>

        </section>
</template>

<script>
export default {
    name: "ContactComponents"
}
</script>